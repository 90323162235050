/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { Box, Heading } from "@chakra-ui/react";

type ErrorHandlerProps<P extends object> = {
	error: any;
	component: FC<P>;
};

export const ErrorHandler: FC<ErrorHandlerProps<any>> = ({ error, component }) => {
	const componetName = component?.displayName || component?.name || "Component";
	console.error(`Server Error in ${componetName}\n`, error);

	return (
		<Box bg="#fffdcb" padding="20px">
			<Heading as="h3" color="red" fontFamily="verdana" fontSize="18pt" fontWeight="normal" margin="0 0 10px 0">Server Error in {componetName}</Heading>
			<Box color="maroon" fontFamily="verdana" fontSize="14pt" fontStyle="italic" fontWeight="normal">
				{error.message}
			</Box>
		</Box>
	);
};
