/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { ErrorHandler } from "./ErrorHandler";

export function withErrorHandling() {
	return function WithErrorHandling<P extends object>(InputComponent: React.FC<P>) {

		const ReactErrorHandled = (props: P) => {
			try {
				return InputComponent(props);
			} catch (e) {
				return <ErrorHandler error={e} component={InputComponent} />;
			}
		}

		ReactErrorHandled.displayName = "ErrorHandler";

		return ReactErrorHandled;
	};
}