import React, { FC, useEffect, useRef, useState } from "react";
import { VideoModel, VideoProps } from "../generated-types";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { GenericHeader } from "~/foundation/Components/GenericHeader";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { AspectRatio, Box, Text as ChakraText, useBreakpointValue } from "@chakra-ui/react";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { imageSizes, themable } from "~/foundation/Theme";
import { pushDataLayer } from "~/foundation/Tracking/GoogleTagManager";
import { Schema } from "~/foundation/Tracking/Schema";
import { useParallax } from "react-scroll-parallax";
import { withErrorHandling } from "~/foundation/ErrorHandling";
import { VideoStyling } from "./styles";
import { CookieConsent } from "~/foundation/OneTrust/CookieConsent";
import { getOneTrustTargetingConsent } from "~/foundation/Utils/cookie";
import { LazyImage } from "~/foundation/Components/Image/LazyImage";
import { PlayIcon } from "./PlayIcon";
import { useEventEmitter } from "~/foundation/Events";
import { OneTrustEvent } from "~/foundation/OneTrust/OneTrustEvent";
import { useInView } from "framer-motion";

function getThumbnailSrcset(video?: VideoModel) {
	if (!video) {
		return "";
	}

	const sources: string[] = [];

	if (video.thumbnailMd) {
		sources.push(`${video.thumbnailMd} 500w`);
	}
	if (video.thumbnailLg) {
		sources.push(`${video.thumbnailLg} 1000w`);
	}
	if (video.thumbnailXl) {
		sources.push(`${video.thumbnailXl} 1760w`);
	}

	if (sources.length === 0) {
		return "";
	}

	return sources.join(",");
}

type Video = {
	type: "YouTube" | "Vimeo";
	id: string;
	src: string;
}

const Video: FC<VideoProps> = ({ fields, rendering, params }) => {
	if (!fields) {
		return <></>;
	}

	const [hasTargetingConsent, setHasTargetingConsent] = useState(false);
	const eventEmitter = useEventEmitter<OneTrustEvent>("OneTrust");
	const [targetElement, setTarget] = useState();
	const targetRef = useRef();
	const moduleRef = useRef(null);
	const isInView = useInView(moduleRef);
	const { sitecoreContext } = useSitecoreContext();
	const [loaded, setLoaded] = useState<boolean>(false);
	const zoom = params.zoom === "1";
	const isSmallViewport = useBreakpointValue({ base: true, "2xl": false }, { ssr: true });
	const autoPlay = fields.autoplayVideo?.value;
	const moduleSpacingNone = params.moduleSpacing === "none";

	useEffect(() => {
		setHasTargetingConsent(getOneTrustTargetingConsent());
		eventEmitter.on("onConsentChanged", () => {
			setHasTargetingConsent(getOneTrustTargetingConsent());
		})
	}, [])

	useEffect(() => {
		setTarget(targetRef.current);
	}, [targetRef]);

	let parallax = {
		ref: React.createRef<HTMLDivElement>()
	};

	if (zoom) {
		parallax = useParallax<HTMLDivElement>({
			easing: "easeIn",
			scale: [isSmallViewport ? .85 : .7, 1],
			targetElement: targetElement,
			onChange: (element) => {
				element.el.classList.toggle("no-border-radius", element.progress === 0.8)
			}
		});
	}

	let video: Video | null = null;

	if (fields.vimeoID?.value) {
		video = {
			type: "Vimeo",
			id: fields.vimeoID.value,
			src: `https://player.vimeo.com/video/${fields.vimeoID.value}?h=b74c66a7ef&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&controls=${autoPlay ? "0" : "1"}&muted=${autoPlay ? "1" : "0"}&loop=${autoPlay ? "1" : "0"}`
		};
	}

	if (fields.youTubeID?.value) {
		video = {
			type: "YouTube",
			id: fields.youTubeID.value,
			src: `https://www.youtube.com/embed/${fields.youTubeID.value}?modestbranding=1&;autohide=1;&rel=0&playsinline=1&autoplay=1&mute=${autoPlay ? "1" : "0"}&loop=${autoPlay ? "1" : "0"}&controls=${autoPlay ? "0" : "1"}&playlist=${fields.youTubeID.value}`
		};
	}

	return (
		<Ribbon
			pt={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}
			pb={moduleSpacingNone ? "0" : ["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}
			{...VideoStyling}>
			<ContentWrapper py={["0", null]}>
				<GenericHeader {...fields}/>
			</ContentWrapper>
			<ContentWrapper
				ref={moduleRef}
				py={["0", null]}
				px={zoom ? "0" : ['var(--chakra-sizes-pageMarginSM)', null, "8", null, '16']}
				maxW={zoom ? ["100%", null, null, "100%"] : [null, null, "contentWrapperWidthMD", "contentWrapperWidthLG", "contentWrapperWidthXL", "contentWrapperWidth2XL"]}
			>
				{video ?
					<Box borderRadius="lg" borderBottomRightRadius="0" overflow="hidden" ref={parallax.ref} className="video-wrapper">
						<Schema schema={{
							"@context": "https://schema.org",
							"@type": "VideoObject",
							name: fields.captionTitle?.value || rendering.customData?.title || fields.headline?.value,
							description: fields.captionText?.value || rendering.customData?.description || fields.description?.value,
							thumbnailUrl: rendering.customData?.thumbnailLg,
							uploadDate: rendering.customData?.uploadDate,
							duration: rendering.customData?.duration?.toString(),
							embedUrl: video.src,
							isPartOf: {
								"@type": "WebPage",
								"@id": `${sitecoreContext.custom.absoluteUrl}#WebPage`
							},
							publisher: {
								"@type": "Corporation",
								"@id": `${sitecoreContext.custom.hostname}#Corporation`,
								name: "Aramco",
								url: sitecoreContext.custom.hostname,
							}
						}} />
						<Box ref={targetRef as never} h="1px" w="1px" />
						<AspectRatio ratio={16 / 9}>
							{
								loaded ||
								(autoPlay && isInView && !sitecoreContext.custom.settings.cookieConsent.useOneTrust) ||
								(autoPlay && isInView && hasTargetingConsent && sitecoreContext.custom.settings.cookieConsent.useOneTrust)
									? (
										<iframe
											src={video.src}
											allow="autoplay; fullscreen; picture-in-picture"
											allowFullScreen
											style={{ border: "0", pointerEvents: autoPlay ? "none" : "auto" }}/>
									)
									: !hasTargetingConsent && sitecoreContext.custom.settings.cookieConsent.useOneTrust
										? (
											<Box
												width="100%"
												height="100%"
												cursor="pointer"
												color="primary.white"
											>
												<CookieConsent position="absolute" zIndex="2" />
												<Box
													className="video-wrapper__container onetrust-toggle optanon-category-C0004"
													data-src=""
													width="100%"
													height="100%"
													cursor="pointer"
													color="primary.white"
													transitionDuration="0.25s"
													transitionTimingFunction="ease-in-out"
													_hover={{ color: "primary.aramcoBlue" }}
													onClick={() => {
														setLoaded(true);
														pushDataLayer(() => ({
															event: "GAevent",
															event_type: "click",
															event_name: "video",
															id: video!.id,
															type: video!.type
														}));
													}}>
													<PlayIcon />
													<Box w="100%" h="100%">
														{fields.thumbnail?.value?.src !== "" ?
															<sc.AdvanceImage
																fields={fields}
																fieldName="thumbnail"
																itemId={sitecoreContext.itemId}
																srcSet={[imageSizes.heroCarousel.s, imageSizes.heroCarousel.m, imageSizes.heroCarousel.l]}
																sizes="(min-width: 1760px) 1760px, 100vw"
																w="100%"
															/>
															:
															<LazyImage
																alt="Play"
																sizes="(min-width: 1760px) 1760px, 100vw"
																srcSet={getThumbnailSrcset(rendering.customData)}
																w="100%" />
														}
													</Box>
												</Box>
											</Box>
										)
										:
										<Box
											className="video-wrapper__container onetrust-toggle optanon-category-C0004"
											width="100%"
											height="100%"
											cursor="pointer"
											color="primary.white"
											transitionDuration="0.25s"
											transitionTimingFunction="ease-in-out"
											_hover={{ color: "primary.aramcoBlue" }}
											onClick={() => {
												setLoaded(true);
												pushDataLayer(() => ({
													event: "GAevent",
													event_type: "click",
													event_name: "video",
													id: video!.id,
													type: video!.type
												}));
											}}>
											<PlayIcon />
											<Box w="100%" h="100%">
												{fields.thumbnail?.value?.src !== "" ?
													<sc.AdvanceImage
														fields={fields}
														fieldName="thumbnail"
														itemId={sitecoreContext.itemId}
														srcSet={[imageSizes.heroCarousel.s, imageSizes.heroCarousel.m, imageSizes.heroCarousel.l]}
														sizes="(min-width: 1760px) 1760px, 100vw"
														w="100%"
													/>
													:
													<LazyImage
														alt="Play"
														sizes="(min-width: 1760px) 1760px, 100vw"
														srcSet={getThumbnailSrcset(rendering.customData)}
														w="100%" />
												}
											</Box>
										</Box>
							}
						</AspectRatio>
					</Box>
					:
					<>
						{sitecoreContext.pageEditing &&
							<Box>
								<ChakraText size="xxs">Vimeo Video ID</ChakraText>
								<ChakraText size="body">
									<sc.Text field={fields.vimeoID} />
								</ChakraText>
								<br/>
								<ChakraText size="xxs">Youtube Video ID</ChakraText>
								<ChakraText size="body">
									<sc.Text field={fields.youTubeID} />
								</ChakraText>
							</Box>
						}
					</>
				}
				{fields.captionText &&
					<ChakraText variant="footerBody" maxW={["100%", null, "50%"]} mt="8" ps={["4", null, "16"]} pe={["4", null, "0"]}>
						<sc.Text field={fields.captionText} />
					</ChakraText>
				}
			</ContentWrapper>
		</Ribbon>
	);
};

export default withErrorHandling()(themable()(Video));