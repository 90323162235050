import { Icon } from "~/foundation/Components/Icon";
import { Box } from "@chakra-ui/react";
import React from "react";
import { useSitecoreContext } from "~/foundation/Jss";

export const PlayIcon = () => {
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;

	return (
		<Box
			className="icon-container"
			h={["45px", null, "4rem", "5rem"]}
			w={["45px", null, "4rem", "5rem"]}
			borderWidth={["2px", null, "2.5px"]}
			borderRadius="50%"
			borderColor="currentColor"
			position="absolute"
			top="50%"
			left="50%"
			color="currentColor"
			display="flex"
			justifyContent="center"
			alignItems="center"
			transform="translateX(-50%) translateY(-50%)"
			zIndex="1"
		>
			<Icon variant="Play" w={["12px", null, "20px", "icons.lg"]} className="icon" ms={isRtl ? 0 : ["2px", null, "1"]} me={isRtl ? ["2px", null, "1"] : 0} />
		</Box>
	)
}