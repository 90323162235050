import React, { FC } from "react";
import { Box, BoxProps, Button, Heading, Text as ChakraText } from "@chakra-ui/react";
import { useTranslation } from "~/foundation/Dictionary";
import { GlobalDictionary } from "~/foundation/Dictionary/dictionary";
import { CookieConsentStyling } from "./styles";

type CookieConsentProps = BoxProps;

export const CookieConsent: FC<CookieConsentProps> = (props) => {
	const [t] = useTranslation<GlobalDictionary>();

	return (
		<Box
			className="onetrust-toggle optanon-category-C0004"
			data-src=""
			width="100%"
			height="100%"
			backgroundColor="rgba(0,0,0, .5)"
			display="flex"
			flexWrap="wrap"
			alignContent="center"
			justifyContent="center"
			textAlign="center"
			{...props}
			{...CookieConsentStyling}
		>
			<Box className="onetrust-toggle__content">
				<Heading as="h3" size="xs" pb="2">
					{t("general.cookies.cookieTitle")}
				</Heading>
				<ChakraText className="onetrust-toggle__text" mb="4">
					{t("general.cookies.cookieText")}
				</ChakraText>
				<Button variant="outline" onClick={() => {
					window.OneTrust?.ToggleInfoDisplay();
				}}>
					{t("general.cookies.cookieButtonText")}
				</Button>
			</Box>
		</Box>
	)
}