/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { lazyHydrate } from "~/foundation/Jss";
import { Loadable } from "~/foundation/ReactLoadable";
import { ComponentLoader } from "../Loading";
import { type GenericHeaderProps } from "./GenericHeader";

export const GenericHeader: FC<GenericHeaderProps> = Loadable({
	loader: () => import(
		/* webpackChunkName: "foundation_Components_GenericHeader" */
		/* webpackPrefetch: true */
		'./GenericHeader').then(({ GenericHeader }) => {
		GenericHeader.displayName = "foundation_Components_GenericHeader";
		return lazyHydrate(GenericHeader);
	}),
	loading: (props: { error?: Error | unknown, retry?: () => void }) => <ComponentLoader componentName="foundation_Components_GenericHeader" {...props} />,
	modules: ['foundation_Components_GenericHeader'],
	webpack: () => [require.resolveWeak("./GenericHeader")],
}) as any;