export const VideoStyling = {
	"sx": {
		".video-wrapper": {
			transitionDuration: ".25s",
			transitionTimingFunction: "aramcoCubic",
			transitionProperty: "border-radius",

			"&.no-border-radius": {
				borderRadius: 0
			},

			"&__container[data-src]": {
				display: "none"
			}
		}
	}
}
